import { logError } from 'utils/helpers';

export namespace vwo {
  export const trackRevenueConversion = (total: number) => {
    if (window._vis_opt_queue && window._vis_opt_revenue_conversion) {
      window._vis_opt_queue?.push(function () {
        window._vis_opt_revenue_conversion?.(String(total));
      });
    } else {
      logError('Unable to track revenue conversion', {
        method: 'vwo.trackRevenueConversion',
      });
    }
  };
}
