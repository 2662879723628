import Freshpaint from 'freshpaint';

export namespace freshpaint {
  export const identify: Freshpaint['identify'] = function (...args) {
    window?.freshpaint?.identify?.(...args);
  };
  export const track: Freshpaint['track'] = function (...args) {
    window?.freshpaint?.track?.(...args);
  };
}
