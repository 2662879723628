declare global {
  interface Window {
    dataLayer?: Array<unknown>;
  }
}

export namespace gtm {
  export const track = (payload: { event: string; [x: string]: unknown }) =>
    window?.dataLayer?.push(payload);
}
