// @deprecated - use SHIPPING_TYPE & SHIPPING_OPTIONS instead
export const SHIPPING = {
  // UPS Overnight Airsaver
  EXPRESS: '10',
  // Backend expects 7 for standard shipping
  STANDARD: '7',
  CHECK_ADDRESS: 'https://www.ups.com/address_validator/search?loc=en_US',
  EXPRESS_FEE: 30,
};

export enum SHIPPING_TYPE {
  STANDARD = 7,
  EXPRESS = 10,
}

export const SHIPPING_OPTIONS = {
  [SHIPPING_TYPE.STANDARD]: {
    name: 'Standard',
    fee: 0,
    description: '2-8 business days',
  },
  [SHIPPING_TYPE.EXPRESS]: {
    name: 'Express',
    fee: 30,
    description: '1-3 business days',
  },
};
