import { membershipSkuMapping } from '../constants/membership-skus';
import { MembershipTypeOptions, Product, Variant } from '../types';
import { MembershipCategory } from '../types/membershipSkus';

export const getValueByKey = (obj: any, keyToFind: string): any =>
  Object.entries(obj).reduce(
    (acc, [key, value]: any) =>
      key === keyToFind
        ? acc.concat(value)
        : typeof value === 'object' && value
        ? acc.concat(getValueByKey(value, keyToFind))
        : acc,
    [],
  ) || [];

export const getMembershipProductId = (sku: string): string => {
  const substr = sku?.includes(MembershipTypeOptions.control)
    ? 'control-membership-'
    : 'sti-membership-';
  const skuId = sku?.replace(substr, '') ?? '';
  const skuProduct = getValueByKey(membershipSkuMapping, skuId);
  return skuProduct ? skuProduct[0]?.pdpSlug : '';
};

export const getVariantPrice = (allSpreeProduct: any, sku: string): string => {
  const product = allSpreeProduct?.find((product: Product) =>
    product?.variants?.some((variant: Variant) => variant?.sku === sku),
  );

  const variant = product?.variants?.find(
    (variant: any) => variant?.sku === sku,
  );

  const price = variant?.price_in_dollars?.replace('.00', '') ?? '';
  return price;
};

export const getProductImage = (allSpreeProduct: any, slug: string): string => {
  const productInfo = allSpreeProduct?.find(
    (item: any) => item?.contentfulProductInfo?.slug === slug,
  );

  return (
    productInfo?.contentfulProductInfo?.boxImage?.gatsbyImageData?.images
      ?.fallback?.src ?? ''
  );
};

const getImagePath = (imgName: string, imageList: string[]) =>
  imageList.find((img) => img.includes(imgName));

const setBoxImage = (object: MembershipCategory, imageList: string[]): any => {
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const items = object[key].items;
      for (const key2 in items) {
        if (items.hasOwnProperty(key2) && items[key2]?.imgName) {
          const item = items[key2];
          item.boxImage = getImagePath(item.imgName, imageList) ?? '';
        }
      }
    }
  }
};

export const getCategoryMap = (imageList: string[]) => {
  const categoryMap = { ...membershipSkuMapping };
  setBoxImage(categoryMap.control, imageList);
  setBoxImage(categoryMap.sti, imageList);

  return categoryMap;
};
