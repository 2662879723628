import mixpanelBrowser, { Callback, Mixpanel } from 'mixpanel-browser';
import { MIXPANEL_API_KEY } from 'utils/constants/mixpanel';

type UserInfo = Parameters<typeof mixpanelBrowser.people.set>[0];

export namespace mixpanel {
  let initialized = false;

  export type TrackCallback = Callback;

  let people: Mixpanel['people'] | null;

  export const init = () => {
    if (MIXPANEL_API_KEY && !initialized) {
      mixpanelBrowser.init(MIXPANEL_API_KEY);

      people = mixpanelBrowser.people;

      initialized = true;
    }
  };

  export const setPeople = function (userInfo: UserInfo) {
    people?.set(userInfo);
  };

  export const identify: Mixpanel['identify'] = function (...args) {
    mixpanelBrowser.identify(...args);
  };

  export const track: Mixpanel['track'] = function (...args) {
    mixpanelBrowser.track(...args);
  };
}
