import { isEmpty, isNil } from 'lodash-es';
import { useEffect, RefObject, useState, useContext } from 'react';

import { ANALYTICS } from './constants/analytics';
import { logError } from './helpers';
import { UserContext } from './hooks/useUserContext/context';
import { mixpanel } from './trackers';
import {
  LineItem,
  User,
  FormattedProductForTracking,
  VariantsSkusType,
} from './types';

export interface TrackProps {
  event: string;
  data?: {
    [prop: string]:
      | LineItem
      | string
      | number
      | boolean
      | undefined
      | string[]
      | FormattedProductForTracking[]
      | VariantsSkusType
      | object;
  };
}

// LineItems could come without a variant sku
const lineItemHasVariantSku = (lineItem: LineItem): boolean =>
  !!lineItem.variant_sku;

export const skusForMixpanel = (
  lineItems: LineItem[] | undefined | null,
): VariantsSkusType => {
  if (isNil(lineItems)) return [];

  return lineItems
    .filter(lineItemHasVariantSku)
    .map((lineItem) => lineItem.variant_sku!);
};

export const track = (props: TrackProps, cb?: mixpanel.TrackCallback): void => {
  let vwoObject = {};
  if (!isEmpty(window._CURRENT_CAMPAIGNS_EW_VWO ?? {})) {
    vwoObject = window._CURRENT_CAMPAIGNS_EW_VWO;
  }
  mixpanel.track(props.event, { ...props.data, ...vwoObject }, cb);
};

export const setUserInfo = (user: User): void => {
  const { id, loggedIn, isMember } = user;
  const userInfo = {
    'Is Member': isMember,
    'Is Logged In': loggedIn,
  };

  if (id) {
    mixpanel.setPeople(userInfo);
    mixpanel.identify(id.toString());
  }
};

/*
 * handle events for analytics tracking
 *
 * required attribute on the HTML element:
 * * data-analytics-event
 *
 * optional attributes:
 * * data-analytics-label
 * * data-analytics-category
 * * data-analytics-test-id
 *
 */
// export function eventHandler(event: Event) {
export const eventHandler = (event: Event): void => {
  event.preventDefault();
  const clickedElement = event.target as HTMLAnchorElement;
  // if the element that was clicked was a child of the element with the data-analytics-event
  const element = clickedElement.closest(
    '[data-analytics-event]',
  ) as HTMLAnchorElement;
  const { href } = element;
  // tracking requires data attributes
  if (!element || !element.dataset) return;

  const { analyticsEvent, analyticsLabel, analyticsCategory, analyticsTestId } =
    element.dataset;

  // event is required
  if (!analyticsEvent) return;
  track(
    {
      data: {
        category: analyticsCategory,
        label: analyticsLabel,
        test_id: analyticsTestId,
      },
      event: analyticsEvent,
    },
    () => {
      if (href) {
        window.location.href = href;
      }
    },
  );
};

const handleTrackableLinkClick = (e: Event): void => {
  eventHandler(e);
};

const useAttachTrackingToLinks = (ref: RefObject<HTMLElement>): void => {
  useEffect(() => {
    let trackableLinks: NodeListOf<HTMLAnchorElement> | null;
    if (ref.current) {
      trackableLinks = ref.current.querySelectorAll('[data-analytics-event]');
      if (trackableLinks) {
        trackableLinks.forEach((link) =>
          link.addEventListener('click', handleTrackableLinkClick),
        );
      }
    }

    return (): void => {
      if (trackableLinks) {
        trackableLinks.forEach((link) =>
          link.removeEventListener('click', handleTrackableLinkClick),
        );
      }
    };
    // FIXME: there are missing dependencies here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);
};

const useIsVisible = (ref: RefObject<HTMLDivElement>, rootMargin = '0px') => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );

    const currentElement = ref?.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) observer.unobserve(currentElement);
    };
    // FIXME: there are missing dependencies here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isVisible;
};
export interface ComponentVisibilityTrackingData {
  label: string;
  slug?: string;
}

// FIXME: this hook does not follow react-hooks rules.
// TODO: move this hook into src/components/TrackVisibilityWrapper/index.tsx
//       since there is the only place this hooks is being used
export const trackComponentVisibility = (
  ref: RefObject<HTMLDivElement>,
  trackingData: ComponentVisibilityTrackingData,
): void => {
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isVisible = useIsVisible(ref);
    const {
      userData: { loggedIn, isMember, membershipType, membershipState },
      // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useContext(UserContext);
    const membershipProps = {
      isMember: Boolean(isMember),
      loggedIn: isMember ? true : loggedIn,
      membershipType: membershipType ? membershipState : undefined,
      membershipState: membershipState ? membershipState : undefined,
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (isVisible) {
        track({
          event: ANALYTICS.EVENTS.VIEWED_COMPONENT,
          data: {
            ...trackingData,
            ...membershipProps,
          },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);
  } catch (err: any) {
    logError(err?.message, {
      component: 'useMembershipIntake',
      method: 'onSignUp',
    });
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  setUserInfo,
  track,
  eventHandler,
  useAttachTrackingToLinks,
};
