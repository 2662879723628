function declareJustUno() {
  window.juapp =
    window.juapp ||
    function () {
      (window.juapp.q = window.juapp.q || []).push(arguments);
    };
}

function buildJustUnoOrder(spree_order) {
  return {
    total: spree_order.total,
    subtotal: spree_order.amount,
    tax: 0.0,
    shipping: spree_order.shipping,
    currency: 'USD',
  };
}

function buildJustUnoLineItem(spree_line_item) {
  return {
    productid: spree_line_item.product_id,
    variationid: spree_line_item.variant_id,
    quantity: spree_line_item.quantity,
    price: spree_line_item.total,
  };
}

const initJustUno = () => {
  if (window) {
    /* Cart Global Variables */
    window.appRoot = '';
    /* Just Uno Init */
    // @ts-ignore
    window.ju_num = process.env.JUSTUNO_ACCOUNT_ID;
    window.asset_host = '//cdn.jst.ai/';
    (function (i, s, o, g, r, a, m) {
      // @ts-ignore
      i[r] =
        // @ts-ignore
        i[r] ||
        function () {
          // @ts-ignore
          (i[r].q = i[r].q || []).push(arguments); // eslint-disable-line
        };
      // @ts-ignore
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      // @ts-ignore
      a.async = 1;
      // @ts-ignore
      a.src = g;
      // @ts-ignore
      m.parentNode.insertBefore(a, m);
      // @ts-ignore
    })(window, document, 'script', asset_host + 'vck.js', 'juapp'); // eslint-disable-line
  }
};

const trackJustUnoConversion = (spreeOrder) => {
  declareJustUno();

  var juo = buildJustUnoOrder(spreeOrder);
  juapp('order', spreeOrder.id, juo);

  spreeOrder.line_items.forEach(function (lineItem) {
    var li = buildJustUnoLineItem(lineItem);
    juapp('orderItem', li);
  });
};

export { initJustUno, trackJustUnoConversion };
