import { Document } from '@contentful/rich-text-types';
import { ToastType } from '@everlywell/leaves';
import { AxiosError } from 'axios';
import { ComparisonTableProps } from 'components/ComparisonTable';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import { DisclaimerDataProps } from '../../components/GeneralDisclaimer';
import { ScreenerDataProps } from '../../components/PrePurchaseScreener';
import { ContentfulRichText } from '../contentful/renderRichText';
import { Charity } from './spreeProducts';

export type Variant = {
  id: number;
  is_master: boolean;
  price: string;
  price_in_dollars: string;
  sku: string;
  name?: string;
  subscription_plan?: SubscriptionPlan;
  total_on_hand?: number;
  membershipProductInfo?: ContentfulProductInfo;
};
export type SubscriptionPlan = {
  id: number;
  interval: string;
  interval_count: number;
  name: string;
  percent_discount: number;
};
export type Taxon = {
  id: number;
  name: string;
  taxonomy_id: number;
  taxonomy_name: string;
  position: number;
};
export type Product = {
  id: number;
  name: string;
  description: string;
  slug: string;
  sku: string;
  variants: Variant[];
  content_token: string;
  master_variant_id: number;
  lowest_subscription_price?: string | number;
  subscription: boolean;
  displayable: boolean;
  meta_title: string;
  meta_keywords: string;
  meta_description: string;
  taxons: Taxon[];
  position?: string;
  charities: Charity[];
  popular_description?: string;
  icon_image_url?: string;
};

export type MembershipType = 'control' | 'sti';
export enum MembershipTypeOptions {
  control = 'control',
  current = 'sti',
}

export type SelectOption = {
  value: string | number;
  label: string;
};

export type ImageType = {
  id?: string;
  title?: string;
  description: string;
  gatsbyImageData: IGatsbyImageData;
  file?: {
    url: string;
  };
};

export type Image = {
  gatsbyImageData: IGatsbyImageData;
};

export interface IGatsbyImage {
  gatsbyImageData: IGatsbyImageData;
  description?: string;
}

export type ISchema = Record<string, string>;

export type FaqSchema = {
  question: string;
  answer: string;
}[];

export type CityPagesLocations = {
  name: string;
  address: string;
  full_address: string;
  city: string;
  state: string;
  zipcode: string;
  lat: number;
  lng: number;
  telephone: string;
  distance: number;
  hours: string;
};

export type cityPagesTypes = {
  state: string;
  city: string;
  zipcode: string;
  lat: number;
  lng: number;
  locations: CityPagesLocations[];
};

export type PageContext = {
  nodeIndex: number;
  productInfoMap: ProductInfoMap;
  seoPage: boolean;
  subtitle: DynamicSubtitle;
  title: DynamicTitle;
  faqSchema?: FaqSchema;
  description: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  landingPageData?: any;
  productsBySlug?: ProductsBySlugType;
};

export type Marker = {
  title: string;
  description: {
    internal: {
      content: string;
    };
  };
};
export type Markers = {
  markers: Marker[];
};

export type ContentfulProductInfo = {
  boxImage?: {
    gatsbyImageData: IGatsbyImageData;
  };
  displayName?: string;
  shortDescription?: string;
  medicalDisclaimer?: {
    medicalDisclaimer: string;
  };
  productCardImage?: ImageType;
  slug?: string;
  collectionMethods?: CollectionMethods[] | undefined;
  symptomList?: {
    symptoms: Symptom[];
  };
  category?: ContentfulProductCategory;
  id?: string | undefined;
  outOfStock?: boolean;
  onSale?: boolean;
  isNew?: boolean;
  promoText?: string;
  productId?: number;
};

export type Symptom = {
  title: string;
};

export type CollectionMethods = {
  id: string;
  name: string;
};

export type ContentfulProductCategory = {
  id: string;
  title: string;
};

export type SuggestedProduct = {
  measures: string;
  title: string;
  displayName: string;
  slug: string;
  description: string;
  pdpImage: Image;
  price: number;
  productId: number;
  markerList: Markers;
  productCardImage?: Image;
};
export type ProductDataType = {
  activeCredits: number;
  category: string;
  categories?: string[];
  description: string;
  displayName: string;
  markerList: Markers;
  measures: string;
  memberHeroCopy?: string;
  memberPrice?: string;
  name: string;
  outOfStock?: boolean;
  pdpImage: ImageType;
  bestSellerOrder?: number;
  newestFirstOrder?: number;
  onSale?: boolean;
  isNew?: boolean;
  shortDescription?: string;
  promotionable?: boolean;
  price: number;
  productCardImage?: ImageType;
  boxImage?: ImageType;
  productId: number;
  promoText?: string;
  sku: string;
  slug: string;
  title: string;
  variantId: number;
  screener?: ScreenerDataProps;
  disclaimer?: DisclaimerDataProps;
};
export type Reviews = {
  nodes: ContentfulReview[];
};
export type ContentfulProduct = {
  nodes: AllProductInfoType[];
};
export type PageProductPromo = {
  product: ProductPromoData;
};
export type PromoDataResult = {
  nodes: PageProductPromo[];
};
export type Content = {
  json: Document;
};
export type ContentSection = {
  id: string;
  sectionId: string;
  title: string;
  headline: string;
  subHeadline: string;
  image: ImageType;
  description: {
    description: string;
    childMarkdownRemark: {
      html: string;
    };
  };
  desktopImages?: {
    gatsbyImageData: IGatsbyImageData;
    description: string;
  }[];
  mobileImages?: {
    gatsbyImageData: IGatsbyImageData;
    description: string;
  }[];
  cta?: CTA;
  listOfContentSections: ContentSection[];
};
export type AdditionalInfoType = {
  icon: Image;
  content?: Content;
  linkText?: string;
  linkUrl?: string;
  medicalReviewer?: string;
  medicalReviewerLink?: string;
  seoPage?: boolean;
};
export type DynamicTitle = {
  json: Document;
};
export type DynamicSubtitle = {
  json: Document;
};
export type ProductInfoMap = {
  [prop: number]: {
    price: string;
    variantId: number;
    sku: string;
    name?: string;
    slug: string;
  };
};

export type SectionType = {
  backgroundColor: string;
  content: ContentfulRichText;
  funFact: AdditionalInfoType;
  image: Image;
  recommendedProducts: SuggestedProduct[];
  returnIcon: Image;
  returnText: string;
  subtitle: ContentfulRichText;
  summaryText: string;
  title: string;
};
export type NavItem = {
  label: string;
  url?: string;
  link?: string;
  className?: string;
  dataTest?: string;
  dataAttributes?: Record<string, string>;
  style?: Record<string, string>;
  htmlId: string;
  classsName?: string;
  category?: string;
  items?: NavItem[];
};
export type SummaryType = {
  returnIcon: IGatsbyImageData;
  returnText: string;
  summaryText: string;
};
export type User = {
  id?: number | string;
  loggedIn?: boolean;
  isMember?: boolean;
};

/**
 * TODO - properties that are returned by the API consistently should be marked as required
 */
export type LineItem = {
  id?: number;
  order_id?: number;
  product_id?: number;
  variant_id: number; // eslint-disable-line camelcase
  preswapped_variant_id: number;
  variant_sku?: string;
  product_name?: string;
  product_sku?: string;
  quantity: number;
  total?: string;
  amount?: string;
  base_price?: string;
  is_subscription?: boolean;
  subscription_frequency?: string | null;
  is_lab_visit?: boolean;
  max_quantity?: number;
  paypal_plan_id?: string | null;
};
export type AxiosErrorResponse = AxiosError<{
  errors: string[] | string;
}>;
export type CTA = {
  text: string;
  url: string;
  appearance?: 'primary' | 'secondary' | 'hero';
};
export type ListOfContentWithImages = {
  identifier: string;
  featureFlag: boolean;
  description: {
    description: string;
  };
  callout?: string;
};
export type ContentWithImage = {
  slug?: string;
  heading: string;
  description?: {
    childMarkdownRemark: {
      html: string;
    };
    description: string;
  };
  image: IGatsbyImage;
  cta?: CTA;
  number?: number;
  identifier?: string;
  subheading?: string;
  featureFlag?: boolean;
  listOfContentWithImages?: ListOfContentWithImages[];
};

export type Slide = {
  heading: string;
  title?: string;
  description: {
    description: string;
  };
  image: IGatsbyImage;
  subheading: string;
};

export enum SectionBackgroundType {
  FullWidth = 'FullWidth',
  WithMargin = 'WithMargin',
  None = 'None',
}

export type PageSectionType = {
  sectionId: string;
  featureFlag: boolean;
  title?: string;
  headline?: string;
  headlineIcon?: ImageType;
  subHeadline?: string;
  listOfContentWithImages?: ContentWithImage[];
  componentName: string;
  componentVariation?: string;
  mainCta?: CTA;
  cta?: CTA;
  backgroundColor?: 'Primary' | 'Secondary';
  slug?: string;
  subHeadlineDescription?: {
    childMarkdownRemark: {
      html: string;
    };
    subHeadlineDescription: string;
  };
  description?: {
    childMarkdownRemark?: {
      html: string;
    };
    description: string;
  };
  className?: string;
  embeddedVideoLink?: string;
  desktopImages?: ImageType[];
  mobileImages?: ImageType[];
  productsSection?: ProductSection;
  bulletPoints?: BulletPoint[];
  backgroundType?: SectionBackgroundType;
  isImageOnTheSide?: boolean;
  modalButtonText?: string;
  listOfLinks?: CTA[];
  disclaimer?: DisclaimerDataProps;
};

export type ProductSection = {
  bulletPoints?: BulletPoint[];
  products: ProductDataType[];
  productBackgroundColorList: string[];
};

export type BulletPoint = {
  id: string;
  icon: ImageType;
  text: ContentfulRichText;
  target?: string;
};

export interface HeroSectionType {
  headline: string;
  headlineMarkdown?: ContentfulHtmlLongText;
  mainCta?: CTA;
  description?: string;
  descriptionMarkdown?: ContentfulHtmlLongText;
  backgroundColor?: 'Primary' | 'Secondary';
  secondaryCtaList?: CTA[];
  secondaryHeadline?: string;
  desktopImage: IGatsbyImage;
  mobileImage: IGatsbyImage;
  listOfContentWithImages: Array<{
    heading: string;
    image: IGatsbyImage;
  }>;
  topBanner?: {
    headline: string;
    mainCta: {
      text: string;
      url: string;
    };
  };
  pinImageToRight?: boolean;
  pinImageToBottom?: boolean;
  imageHeight?: number;
  textColor?: string;
}

export type FormFieldType = {
  name: string;
  value: string;
};
type Promotion = {
  id: number;
  code: string;
  name: string;
  volume_discount_quantity?: number;
  description: string;
  line_item_discount: string | null;
};

export type GiftCard = {
  adjustment_id: number;
  amount: string;
  gift_card_code: string;
  remaining_value: number;
};

export type Order = {
  id: number;
  email?: string;
  number: string;
  user_id?: string;
  line_items: LineItem[];
  state: string;
  total: string;
  amount: string;
  ship_address_id?: string;
  bill_address_id?: string;
  promotions: Promotion[];
  promo_total: string;
  token: string;
  registration_token: string;
  shipping?: string;
  tax_total?: string | number;
  gift_card_total: string;
  gift_cards: GiftCard[];

  payment_intent?: {
    id: string;
    client_secret: string;
  } | null;
};
export type Errors = string[] | null;
export type CartTotalType = {
  productQuantity: number;
  totalPrice: number;
  subTotal: number;
  currencyId: string;
  currencyFormat: string;
};

export type ShippingAddressType = {
  addressLine: string[];
  city: string;
  country: string;
  dependentLocality: string;
  organization: string;
  phone: string;
  postalCode: string;
  recipient: string;
  region: string;
  sortingCode: string;
};
export type StripeCardType = {
  address_city: string;
  address_country: string;
  address_line1: string;
  address_line1_check: string;
  address_line2?: string;
  address_state: string;
  address_zip: string;
  address_zip_check: string;
  brand: string;
  country: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cvc_check?: any;
  dynamic_last4: string;
  exp_month: number;
  exp_year: number;
  funding: string;
  id: string;
  last4: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: any;
  name: string;
  object: string;
  tokenization_method: string;
};
export type FAQ = {
  question: string;
  answer: string;
};

export type CollectionMethodContentfulType = {
  v2Name: string;
  v2Icon: { file: { url: string } };
};
export type SubscriptionVariantType = Variant;

export type InnerProductBySlug = {
  price: string;
  slug: string;
  canonicalUrl: string;
  name: string;
  boxImage: ImageType;
  cartImage: IGatsbyImageData;
  variantId: number;
  productId: number;
  screener: ScreenerDataProps;
  disclaimer: DisclaimerDataProps;
  isNew?: boolean;
  onSale?: boolean;
  callout?: string;
  reviewData: {
    average_rating: number;
    review_count: number;
    rating_histogram: number[];
    recommended_ratio: number;
  };
  outOfStock?: boolean | null;
  showRating: boolean;
};
export interface ProductsBySlugType {
  [key: string]: InnerProductBySlug;
}
export type ProductBySlugType = {
  contentfulProductInfo: ContentfulProductInfo;
  master_variant_id: number;
  productId: string;
  slug: string;
  variants: Variant[];
};

export type ProductVariant = {
  price: string;
  name: string;
  slug: string;
  canonicalUrl: string;
  variantId: number;
  sku: string;
  subscriptionVariants: Variant[];
  metaDescription: string;
  metaTitle: string;
  metaKeywords: string;
  promotionable: boolean;
  maxQuantity: string;
};

export interface ProductVariants {
  [key: number]: ProductVariant;
}

export type ProductBySKUType = {
  contentfulProductInfo: ContentfulProductInfo;
  master_variant_id: number;
  productId: string;
  sku: string;
  variants: Variant[];
};

export type FormattedProductForTracking = {
  name: string;
  id: number;
  price: number;
  variant: string;
  quantity: number;
  is_subscription: boolean | null;
  subscription_frequency: string | null;
};
export type VariantsSkusType = string[];

export type NotificationType = {
  message: string;
  source: string;
  id: string;
  type?: ToastType;
  persist?: boolean;
  details?: JSX.Element | string;
  mixpanelLabel?: string;
  onlyShowOnce?: boolean;
};
export enum MembershipStatus {
  Active = 'active',
  Archived = 'archived',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Paused = 'paused',
}
export const ActiveMembershipStatuses = [
  MembershipStatus.Active,
  MembershipStatus.Failed,
  MembershipStatus.Paused,
];
export type IterableProduct = {
  id: number;
  name: string;
  price: number;
};
export interface PDPPageSectionType extends PageSectionType {
  title: string;
  componentName: string;
  slug: string;
  listOfContentSections?: Array<{
    sectionId: string;
    title: string;
    headline?: string;
    subHeadline?: string;
    image?: ImageType;
    description?: {
      description: string;
    };
    desktopImages?: Array<ImageType>;
    mobileImages?: Array<ImageType>;
    modalButtonText?: string;
    modalTitle?: string;
    slug?: string;
    embeddedVideoLink?: string;
    listOfContentSections?: Array<{
      title: string;
      image: ImageType;
      description: {
        description: string;
      };
    }>;
  }>;
  description?: {
    description: string;
  };
  desktopImages?: Array<ImageType>;
  mobileImages?: Array<ImageType>;
}
export interface PDPPageReview {
  location: string;
  rating: number;
  author: string;
  headline: string;
  created_date: number;
  comments: string;
}
export interface AllProductInfoType {
  name: string;
  price: string;
  productId: number;
  pageSections: PDPPageSectionType[];
  showRating: boolean;
  heroSection: {
    consultAvailable: boolean;
    consultAvailableText: string;
    heroDescriptionHeader: string;
    heroDescriptionText: string;
    heroMeasuresText: string;
    heroMeasuresIcon?: {
      file: {
        url: string;
      };
    };
    heroPurchaseDetailsCallout: string;
    heroPurchaseDetailsBottomCallout: string;
    heroSliderImages: Array<ImageType>;
    heroTopCallout: string;
    heroBottomCallout: string;
    heroDescriptionCallout?: string;
    heroModalTitle: string;
    heroModalButtonText: string;
    heroMeasuresContentSection: {
      listOfContentSections: Array<{
        title: string;
        description: {
          description: string;
        };
        image: ImageType;
      }>;
    };
  };
  sku: string;
  upc: string;
  slug: string;
  description: string;
  onSale: boolean;
  isNew: boolean;
  reviewData: {
    average_rating: number;
    review_count: number;
    rating_histogram: number[];
    recommended_ratio: number;
    contents: PDPPageReview[];
  };
  primaryCallouts: Array<{ text: string }>;
  markerList: {
    markers: Array<{
      title: string;
      description: {
        description: string;
      };
    }>;
  };
  metaDescription: string;
  metaKeywords: string;
  metaTitle: string;
  productSchema: ISchema;
  faqSchema: FaqSchema;
  variantId: number;
  collectionMethods: Array<CollectionMethodContentfulType>;
  subscriptionVariants: Array<SubscriptionVariantType>;
  digDeeperSections: Array<{
    question: string;
    answer: { answer: string };
  }>;
  heroImages: Array<ImageType>;
  productsBySlug: ProductsBySlugType;
  hideSubscription?: boolean;
  preSelectedSubscription?: boolean;
  heroSubscriptionFaqUrl?: string;
  disclaimer: any; // TODO fix type
  screener: any; // TODO fix type
  comparisonTable?: ComparisonTableProps;
  canonicalUrl: string;
  promotionable?: boolean;
  outOfStock: boolean | null;
  maxQuantity?: number;
}

export type CreditRedemptionRequest = { user_id: number; variant_id: number };

export type FormValueType = string | boolean | number;

export type FormValuesType = {
  [key: string]: FormValueType;
};

export type ContentfulReview = {
  product_name: string;
  product_page_id: string;
  average_rating: number;
  native_review_count: number;
  question_count: number;
  rating_histogram: number[];
  recommended_ratio: number;
  review_count: number;
  syndicated_review_count: number;
};

export type ProductPromoData = {
  slug: string;
  onSale: boolean;
  promoText: string;
};

export type ContentfulHtmlLongText = {
  childMarkdownRemark: {
    html: string;
  };
};

export interface CategoryPageSectionType extends PageSectionType {
  listOfContentSections?: ContentSection[];
  noNumberTags?: boolean;
}

export type ProductCategory = {
  pipId: number;
  title: string;
  product: Product[];
  order: number;
  promoActive: boolean;
  promoColumn: number;
  promoRow: number;
  promoSpan: number;
  promo: {
    slug: string;
    content: string;
    link: string;
    image: ImageType;
  };
};

export type ProductBadgeConfig = {
  text: string;
  color: string;
};

export type FAQCase = {
  title: string;
  description: ContentfulRichText;
  image: IGatsbyImage;
};

export type FlagsType =
  | Queries.Maybe<readonly Queries.Maybe<Queries.ContentfulFlag001>[]>
  | undefined;
