import axios from 'axios';
import { STORE_ROOT } from 'utils/constants/urls';
import { UserSession } from 'utils/types/sessionTypes';

import { StoreApi } from './instances';

export const getUserSession = async () => {
  const response = await axios.get<UserSession>(
    `${STORE_ROOT}/sessions/status.json`,
    {
      withCredentials: true,
    },
  );

  StoreApi.defaults.headers.Authorization = `Bearer ${response.data.auth_token}`;

  return response;
};
