import React from 'react';
import { Script } from 'gatsby';
import { QueryClientProvider } from '@tanstack/react-query';
import { UserContextProvider } from './src/utils/hooks/useUserContext/context';
import { MembershipCheckoutProvider } from './src/components/MembershipUtils/MembershipCheckoutContext/MembershipCheckoutContext';
import { queryClient } from './src/utils/api/instances';

import '@everlywell/leaves/lib/style.css';

export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <MembershipCheckoutProvider>
          <Script src="https://cdn.levelaccess.net/accessjs/YW1wMTMwOTk/access.js" />
          {element}
          <Script src="https://refer.everlywell.com/core.js" />
          <Script src="https://unpkg.com/@phosphor-icons/web@2.1.1" />
        </MembershipCheckoutProvider>
      </UserContextProvider>
    </QueryClientProvider>
  );
};
