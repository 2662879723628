import { MembershipCategory } from '../types/membershipSkus';

/***
 * Provides a mapping between the Membership Product test kit Variant skus
 * and their corresponding tesk kit Product slug.
 *
 * We currently keep the Membership kit variants on the Membership Products, so
 * we need a way to associate those items. We have a similar map in Store, since
 * we pass the variant in the Credit Redemption flow. Perhaps it could be simplified
 * if we passed the product sku instead.
 */

export const membershipSkuMapping: Record<
  'control' | 'sti' | 'heart',
  MembershipCategory
> = {
  control: {
    '1-credit-tests': {
      label: '1 Credit Tests',
      items: {
        'cholesterol-and-lipids': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'cholesterol-lipids-box@2x',
          originalPrice: '$49',
          pdpSlug: 'cholesterol-and-lipids-test',
          title: 'Cholesterol & Lipids Test',
          variantInfo: {
            'cholesterol-and-lipids-test': 511,
          },
        },
        hba1c: {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'heart-health-box@2x',
          originalPrice: '$49',
          pdpSlug: 'hba1c',
          title: 'HbA1c Test',
          variantInfo: {
            hba1c: 510,
          },
        },
        testosterone: {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'testosterone-box@2x',
          originalPrice: '$69',
          pdpSlug: 'testosterone-test',
          title: 'Total Testosterone Test - Male',
          variantInfo: {
            'testosterone-test': 514,
          },
        },
        'chlamydia-and-gonorrhea': {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'chlamydia-gonorrhea-box@2x',
          originalPrice: '$69',
          pdpSlug: 'chlamydia-gonorrhea-test',
          title: 'Chlamydia & Gonorrhea Test',
          variantInfo: {
            'chlamydia-gonorrhea-test': 505,
          },
        },
        'vitamin-d': {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'v-itamin-d-box@2x',
          originalPrice: '$49',
          pdpSlug: 'vitamin-d-test',
          title: 'Vitamin D Test',
          variantInfo: {
            'vitamin-d-test': 515,
          },
        },
        syphillis: {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'syphilis-box@2x',
          title: 'Syphilis Test',
          originalPrice: '$69',
          pdpSlug: 'syphilis-test',
          variantInfo: {
            'syphilis-test': 506,
          },
        },
        trichomoniasis: {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'trichomoniasis-box@2x',
          originalPrice: '$69',
          pdpSlug: 'trichomoniasis-test',
          title: 'Trichomoniasis Test',
          variantInfo: {
            'trichomoniasis-test': 509,
          },
        },
        'hepatitis-c': {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$69',
          pdpSlug: 'hepatitis-c-test',
          title: 'Hepatitis C Test',
          variantInfo: {
            'hepatitis-c-test': 507,
          },
        },
        'hpv-test-female': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'hpv-box@2x',
          originalPrice: '$69',
          pdpSlug: 'hpv-test-female',
          title: 'HPV Test - Female',
          variantInfo: {
            'hpv-test-female': 518,
          },
        },
        'fit-colon-cancer-screening': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'fit-colorectal-cancer-box@2x',
          originalPrice: '$49',
          pdpSlug: 'fit-colon-cancer-screening-test',
          title: 'FIT Colon Cancer Screening Test',
          variantInfo: {
            'fit-colon-cancer-screening-test': 517,
          },
        },
      },
    },
    '2-credit-tests': {
      label: '2 Credit Tests',
      items: {
        thyroid: {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'thyroid-box@2x',
          originalPrice: '$149',
          pdpSlug: 'thyroid-test',
          title: 'Thyroid Test',
          variantInfo: {
            'thyroid-test': 512,
          },
        },
        'vitamin-d-and-inflammation': {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'vitamin-d-inflammation-box@2x',
          originalPrice: '$99',
          pdpSlug: 'vitamin-d-and-inflammation-test',
          title: 'Vitamin D & Inflammation Test',
          variantInfo: {
            'vitamin-d-and-inflammation-test': 516,
          },
        },
        metabolism: {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'metabolism-box@2x',
          originalPrice: '$99',
          pdpSlug: 'metabolism',
          title: 'Metabolism Test',
          variantInfo: {
            metabolism: 513,
          },
        },
        'heart-health': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'heart-health-box@2x',
          originalPrice: '$99',
          pdpSlug: 'heart-health-test',
          title: 'Heart Health Test',
          variantInfo: {
            'heart-health-test': 1071,
          },
        },
        'celiac-disease-screening-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'Nutritional Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$119',
          pdpSlug: 'celiac-disease-screening-test',
          title: 'Celiac Disease Screening Test',
          variantInfo: {
            'celiac-disease-screening-test': 1450,
          },
        },
        'lyme-disease-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'General Wellness',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$109',
          pdpSlug: 'lyme-disease-test',
          title: 'Lyme Disease Test',
          variantInfo: {
            'lyme-disease-test': 1449,
          },
        },
        'postmenopause-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Women's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$99',
          pdpSlug: 'postmenopause-test',
          title: 'Postmenopause Test',
          variantInfo: {
            'celiac-disease-screening-test': 1451,
          },
        },
        'perimenopause-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Women's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$99',
          pdpSlug: 'perimenopause-test',
          title: 'Perimenopause Test',
          variantInfo: {
            'perimenopause-test': 1452,
          },
        },
      },
    },
    '3-credit-tests': {
      label: '3 Credit Tests',
      items: {
        'food-sensitivity': {
          additionalInfo: '',
          boxImage: '',
          category: 'Nutritional Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$199',
          pdpSlug: 'food-sensitivity',
          title: 'Food Sensitivity Test',
          variantInfo: {
            'food-sensitivity': 1442,
          },
        },
        'womens-fertility-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Women's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$149',
          pdpSlug: 'womens-fertility-test',
          title: "Women's Fertility Test",
          variantInfo: {
            'womens-fertility-test': 1443,
          },
        },
        'food-allergy-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'Nutritional Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$149',
          pdpSlug: 'food-allergy-test',
          title: 'Food Allergy Test',
          variantInfo: {
            'food-allergy-test': 1441,
          },
        },
      },
    },
    '4-credit-tests': {
      label: '4 Credit Tests',
      items: {
        'food-sensitivity-comprehensive-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'Nutritional Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$299',
          pdpSlug: 'food-sensitivity-comprehensive-test',
          title: 'Food Sensitivity Comp. Test',
          variantInfo: {
            'food-sensitivity-comprehensive-test': 1440,
          },
        },
        'womens-health-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Women's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$249',
          pdpSlug: 'womens-health-test',
          title: "Women's Health Test",
          variantInfo: {
            'womens-health-test': 1448,
          },
        },
        'mens-health-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Men's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$249',
          pdpSlug: 'mens-health-test',
          title: "Men's Health Test",
          variantInfo: {
            'mens-health-test': 1447,
          },
        },
        'indoor-outdoor-allergy-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'General Wellness',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$199',
          pdpSlug: 'indoor-outdoor-allergy-test',
          title: 'Indoor & Outdoor Allergy Test',
          variantInfo: {
            'food-sensitivity-comprehensive-test': 1446,
          },
        },
      },
    },
  },
  sti: {
    '1-credit-tests': {
      label: '1 Credit Tests',
      items: {
        'chlamydia-and-gonorrhea': {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'chlamydia-gonorrhea-box@2x',
          originalPrice: '$69',
          pdpSlug: 'chlamydia-gonorrhea-test',
          title: 'Chlamydia & Gonorrhea Test',
          variantInfo: {
            'chlamydia-gonorrhea-test': 505,
          },
        },
        syphillis: {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'syphilis-box@2x',
          title: 'Syphilis Test',
          originalPrice: '$69',
          pdpSlug: 'syphilis-test',
          variantInfo: {
            'syphilis-test': 506,
          },
        },
        trichomoniasis: {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'trichomoniasis-box@2x',
          originalPrice: '$69',
          pdpSlug: 'trichomoniasis-test',
          title: 'Trichomoniasis Test',
          variantInfo: {
            'trichomoniasis-test': 509,
          },
        },
        'hepatitis-c': {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$69',
          pdpSlug: 'hepatitis-c-test',
          title: 'Hepatitis C Test',
          variantInfo: {
            'hepatitis-c-test': 507,
          },
        },
        'hpv-test-female': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'hpv-box@2x',
          originalPrice: '$69',
          pdpSlug: 'hpv-test-female',
          title: 'HPV Test - Female',
          variantInfo: {
            'hpv-test-female': 518,
          },
        },
        'cholesterol-and-lipids': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'cholesterol-lipids-box@2x',
          originalPrice: '$49',
          pdpSlug: 'cholesterol-and-lipids-test',
          title: 'Cholesterol & Lipids Test',
          variantInfo: {
            'cholesterol-and-lipids-test': 511,
          },
        },
        hba1c: {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'heart-health-box@2x',
          originalPrice: '$49',
          pdpSlug: 'hba1c',
          title: 'HbA1c Test',
          variantInfo: {
            hba1c: 510,
          },
        },
        testosterone: {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'testosterone-box@2x',
          originalPrice: '$69',
          pdpSlug: 'testosterone-test',
          title: 'Total Testosterone Test - Male',
          variantInfo: {
            'testosterone-test': 514,
          },
        },
        'vitamin-d': {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'v-itamin-d-box@2x',
          originalPrice: '$49',
          pdpSlug: 'vitamin-d-test',
          title: 'Vitamin D Test',
          variantInfo: {
            'vitamin-d-test': 515,
          },
        },
        'fit-colon-cancer-screening': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'fit-colorectal-cancer-box@2x',
          originalPrice: '$49',
          pdpSlug: 'fit-colon-cancer-screening-test',
          title: 'FIT Colon Cancer Screening Test',
          variantInfo: {
            'fit-colon-cancer-screening-test': 517,
          },
        },
      },
    },
    '2-credit-tests': {
      label: '2 Credit Tests',
      items: {
        thyroid: {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'thyroid-box@2x',
          originalPrice: '$149',
          pdpSlug: 'thyroid-test',
          title: 'Thyroid Test',
          variantInfo: {
            'thyroid-test': 512,
          },
        },
        'vitamin-d-and-inflammation': {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'vitamin-d-inflammation-box@2x',
          originalPrice: '$99',
          pdpSlug: 'vitamin-d-and-inflammation-test',
          title: 'Vitamin D & Inflammation Test',
          variantInfo: {
            'vitamin-d-and-inflammation-test': 516,
          },
        },
        metabolism: {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'metabolism-box@2x',
          originalPrice: '$99',
          pdpSlug: 'metabolism',
          title: 'Metabolism Test',
          variantInfo: {
            metabolism: 513,
          },
        },
        'heart-health': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'heart-health-box@2x',
          originalPrice: '$99',
          pdpSlug: 'heart-health-test',
          title: 'Heart Health Test',
          variantInfo: {
            'heart-health-test': 1071,
          },
        },
        'celiac-disease-screening-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'Nutritional Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$119',
          pdpSlug: 'celiac-disease-screening-test',
          title: 'Celiac Disease Screening Test',
          variantInfo: {
            'celiac-disease-screening-test': 1450,
          },
        },
        'lyme-disease-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'General Wellness',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$109',
          pdpSlug: 'lyme-disease-test',
          title: 'Lyme Disease Test',
          variantInfo: {
            'lyme-disease-test': 1449,
          },
        },
        'postmenopause-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Women's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$99',
          pdpSlug: 'postmenopause-test',
          title: 'Postmenopause Test',
          variantInfo: {
            'celiac-disease-screening-test': 1451,
          },
        },
        'perimenopause-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Women's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$99',
          pdpSlug: 'perimenopause-test',
          title: 'Perimenopause Test',
          variantInfo: {
            'perimenopause-test': 1452,
          },
        },
      },
    },
    '3-credit-tests': {
      label: '3 Credit Tests',
      items: {
        'womens-fertility-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Women's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$149',
          pdpSlug: 'womens-fertility-test',
          title: "Women's Fertility Test",
          variantInfo: {
            'womens-fertility-test': 1443,
          },
        },
        'food-sensitivity': {
          additionalInfo: '',
          boxImage: '',
          category: 'Nutritional Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$199',
          pdpSlug: 'food-sensitivity',
          title: 'Food Sensitivity Test',
          variantInfo: {
            'food-sensitivity': 1442,
          },
        },
        'food-allergy-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'Nutritional Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$149',
          pdpSlug: 'food-allergy-test',
          title: 'Food Allergy Test',
          variantInfo: {
            'food-allergy-test': 1441,
          },
        },
      },
    },
    '4-credit-tests': {
      label: '4 Credit Tests',
      items: {
        'food-sensitivity-comprehensive-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'Nutritional Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$299',
          pdpSlug: 'food-sensitivity-comprehensive-test',
          title: 'Food Sensitivity Comp. Test',
          variantInfo: {
            'food-sensitivity-comprehensive-test': 1440,
          },
        },
        'womens-health-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Women's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$249',
          pdpSlug: 'womens-health-test',
          title: "Women's Health Test",
          variantInfo: {
            'womens-health-test': 1448,
          },
        },
        'mens-health-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Men's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$249',
          pdpSlug: 'mens-health-test',
          title: "Men's Health Test",
          variantInfo: {
            'mens-health-test': 1447,
          },
        },
        'indoor-outdoor-allergy-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'General Wellness',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$199',
          pdpSlug: 'indoor-outdoor-allergy-test',
          title: 'Indoor & Outdoor Allergy Test',
          variantInfo: {
            'food-sensitivity-comprehensive-test': 1446,
          },
        },
      },
    },
  },
  heart: {
    '1-credit-tests': {
      label: '1 Credit Tests',
      items: {
        'cholesterol-and-lipids': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'cholesterol-lipids-box@2x',
          originalPrice: '$49',
          pdpSlug: 'cholesterol-and-lipids-test',
          title: 'Cholesterol & Lipids Test',
          variantInfo: {
            'cholesterol-and-lipids-test': 511,
          },
        },
        hba1c: {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'heart-health-box@2x',
          originalPrice: '$49',
          pdpSlug: 'hba1c',
          title: 'HbA1c Test',
          variantInfo: {
            hba1c: 510,
          },
        },
        testosterone: {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'testosterone-box@2x',
          originalPrice: '$69',
          pdpSlug: 'testosterone-test',
          title: 'Total Testosterone Test - Male',
          variantInfo: {
            'testosterone-test': 514,
          },
        },
        'vitamin-d': {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'v-itamin-d-box@2x',
          originalPrice: '$49',
          pdpSlug: 'vitamin-d-test',
          title: 'Vitamin D Test',
          variantInfo: {
            'vitamin-d-test': 515,
          },
        },
        'chlamydia-and-gonorrhea': {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'chlamydia-gonorrhea-box@2x',
          originalPrice: '$69',
          pdpSlug: 'chlamydia-gonorrhea-test',
          title: 'Chlamydia & Gonorrhea Test',
          variantInfo: {
            'chlamydia-gonorrhea-test': 505,
          },
        },
        syphillis: {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'syphilis-box@2x',
          title: 'Syphilis Test',
          originalPrice: '$69',
          pdpSlug: 'syphilis-test',
          variantInfo: {
            'syphilis-test': 506,
          },
        },
        trichomoniasis: {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'trichomoniasis-box@2x',
          originalPrice: '$69',
          pdpSlug: 'trichomoniasis-test',
          title: 'Trichomoniasis Test',
          variantInfo: {
            'trichomoniasis-test': 509,
          },
        },
        'hepatitis-c': {
          additionalInfo: '',
          boxImage: '',
          category: 'Sexual Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$69',
          pdpSlug: 'hepatitis-c-test',
          title: 'Hepatitis C Test',
          variantInfo: {
            'hepatitis-c-test': 507,
          },
        },
        'hpv-test-female': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'hpv-box@2x',
          originalPrice: '$69',
          pdpSlug: 'hpv-test-female',
          title: 'HPV Test - Female',
          variantInfo: {
            'hpv-test-female': 518,
          },
        },
        'fit-colon-cancer-screening': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'fit-colorectal-cancer-box@2x',
          originalPrice: '$49',
          pdpSlug: 'fit-colon-cancer-screening-test',
          title: 'FIT Colon Cancer Screening Test',
          variantInfo: {
            'fit-colon-cancer-screening-test': 517,
          },
        },
      },
    },
    '2-credit-tests': {
      label: '2 Credit Tests',
      items: {
        'heart-health': {
          additionalInfo: '',
          boxImage: '',
          category: 'Heart Health & Screening',
          imgName: 'heart-health-box@2x',
          originalPrice: '$99',
          pdpSlug: 'heart-health-test',
          title: 'Heart Health Test',
          variantInfo: {
            'heart-health-test': 1071,
          },
        },
        thyroid: {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'thyroid-box@2x',
          originalPrice: '$149',
          pdpSlug: 'thyroid-test',
          title: 'Thyroid Test',
          variantInfo: {
            'thyroid-test': 512,
          },
        },
        'vitamin-d-and-inflammation': {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'vitamin-d-inflammation-box@2x',
          originalPrice: '$99',
          pdpSlug: 'vitamin-d-and-inflammation-test',
          title: 'Vitamin D & Inflammation Test',
          variantInfo: {
            'vitamin-d-and-inflammation-test': 516,
          },
        },
        metabolism: {
          additionalInfo: '',
          boxImage: '',
          category: 'Energy & Weight',
          imgName: 'metabolism-box@2x',
          originalPrice: '$99',
          pdpSlug: 'metabolism',
          title: 'Metabolism Test',
          variantInfo: {
            metabolism: 513,
          },
        },
        'celiac-disease-screening-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'Nutritional Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$119',
          pdpSlug: 'celiac-disease-screening-test',
          title: 'Celiac Disease Screening Test',
          variantInfo: {
            'celiac-disease-screening-test': 1450,
          },
        },
        'lyme-disease-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'General Wellness',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$109',
          pdpSlug: 'lyme-disease-test',
          title: 'Lyme Disease Test',
          variantInfo: {
            'lyme-disease-test': 1449,
          },
        },
        'postmenopause-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Women's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$99',
          pdpSlug: 'postmenopause-test',
          title: 'Postmenopause Test',
          variantInfo: {
            'celiac-disease-screening-test': 1451,
          },
        },
        'perimenopause-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Women's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$99',
          pdpSlug: 'perimenopause-test',
          title: 'Perimenopause Test',
          variantInfo: {
            'perimenopause-test': 1452,
          },
        },
      },
    },
    '3-credit-tests': {
      label: '3 Credit Tests',
      items: {
        'food-sensitivity': {
          additionalInfo: '',
          boxImage: '',
          category: 'Nutritional Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$199',
          pdpSlug: 'food-sensitivity',
          title: 'Food Sensitivity Test',
          variantInfo: {
            'food-sensitivity': 1442,
          },
        },
        'food-allergy-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'Nutritional Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$149',
          pdpSlug: 'food-allergy-test',
          title: 'Food Allergy Test',
          variantInfo: {
            'food-allergy-test': 1441,
          },
        },
        'womens-fertility-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Women's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$149',
          pdpSlug: 'womens-fertility-test',
          title: "Women's Fertility Test",
          variantInfo: {
            'womens-fertility-test': 1443,
          },
        },
      },
    },
    '4-credit-tests': {
      label: '4 Credit Tests',
      items: {
        'food-sensitivity-comprehensive-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'Nutritional Health',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$299',
          pdpSlug: 'food-sensitivity-comprehensive-test',
          title: 'Food Sensitivity Comp. Test',
          variantInfo: {
            'food-sensitivity-comprehensive-test': 1440,
          },
        },
        'womens-health-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Women's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$249',
          pdpSlug: 'womens-health-test',
          title: "Women's Health Test",
          variantInfo: {
            'womens-health-test': 1448,
          },
        },
        'mens-health-test': {
          additionalInfo: '',
          boxImage: '',
          category: "Men's Health",
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$249',
          pdpSlug: 'mens-health-test',
          title: "Men's Health Test",
          variantInfo: {
            'mens-health-test': 1447,
          },
        },
        'indoor-outdoor-allergy-test': {
          additionalInfo: '',
          boxImage: '',
          category: 'General Wellness',
          imgName: 'hepatitis-c-box@2x',
          originalPrice: '$199',
          pdpSlug: 'indoor-outdoor-allergy-test',
          title: 'Indoor & Outdoor Allergy Test',
          variantInfo: {
            'food-sensitivity-comprehensive-test': 1446,
          },
        },
      },
    },
  },
};
