import Cookies from 'js-cookie';
import React, { createContext, useContext, useState } from 'react';
import { StoreApi } from 'utils/api/instances';
import { isBrowser } from 'utils/helpers';

import { tracker } from '../../../utils/trackers';
import { VisibleForm } from '../MembershipCheckoutForms';
import { AccountFormData, BaseFormData } from '../MembershipSubmissionForm';

type MembershipsResponse = {
  id: string;
  type: string;
  user_id: string;
  order_id: string;
  status: string;
  program_plan_id: string;
};

function isAccountFormData(
  data: BaseFormData | AccountFormData,
): data is AccountFormData {
  return 'firstName' in data && 'lastName' in data;
}

const transformFromData = (
  data: BaseFormData | AccountFormData,
  visibleForm: VisibleForm,
) => {
  const formData = new FormData();

  const isUserSignUp = String(visibleForm === 'account' ? true : false);
  const campaignId = Cookies.get('iterableEmailCampaignId');

  formData.append('variant_slug', data.variantSlug);
  formData.append('wants_marketing', String(!!data.marketingTerms));
  formData.append('payment[payment_token]', data.paymentToken);
  formData.append('user[email]', data.email);
  formData.append('user[password]', data.password);
  formData.append('user[new_record]', isUserSignUp);
  formData.append('user[terms_and_conditions]', String(data.programTerms));

  if (campaignId) {
    formData.append('marketing_campaign_id', campaignId);
  }

  if (data.extoleShareableCode) {
    formData.append('marketing_advocate_code', data.extoleShareableCode);
  }

  if (data.promoCode) {
    formData.append('promotion_code', data.promoCode);
  }

  if (isAccountFormData(data)) {
    formData.append('user[first_name]', data.firstName);
    formData.append('user[last_name]', data.lastName);
  }

  return formData;
};

type MembershipCheckoutContextType = {
  joinMembership: (
    payload: JoinMembershipPayload,
  ) => Promise<MembershipsResponse | void>;
  program?: string | null;
  setProgram: (value: string) => void;
  redirectedToConfirmation: boolean;
  setRedirectedToConfirmation: (value: boolean) => void;
};

type JoinMembershipPayload = {
  data: any;
  formType: VisibleForm;
  userData: {
    userId: number | null;
    userEmail: string | null;
  };
};

export const MembershipCheckoutContext =
  createContext<MembershipCheckoutContextType>({
    joinMembership: async () => {},
    program: null,
    setProgram: () => {},
    redirectedToConfirmation: false,
    setRedirectedToConfirmation: () => {},
  });

export const useMembershipCheckout = () =>
  useContext(MembershipCheckoutContext);

export const MembershipCheckoutProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [redirectedToConfirmation, setRedirectedToConfirmation] =
    useState(false);
  const [program, setProgram] = useState<string | null>(null);

  const joinMembership = async (
    payload: JoinMembershipPayload,
  ): Promise<MembershipsResponse | void> => {
    try {
      const {
        data,
        formType,
        userData: { userId, userEmail },
      } = payload;

      const transformedData = transformFromData(data, formType);

      const { data: membershipsResponse } =
        await StoreApi.post<MembershipsResponse>(
          '/v3/memberships/',
          transformedData,
          {
            ...(!userId && {
              withCredentials: true,
              transformRequest: (data, headers) => {
                delete headers.Authorization;

                return data;
              },
            }),
          },
        );

      if (isBrowser()) {
        tracker.trackMembershipPurchase({
          program: program || 'Program not provided',
          userEmail: String(userEmail || transformedData.get('email')),
          userId: String(userId || membershipsResponse.user_id),
        });

        setRedirectedToConfirmation(true);
      }

      return Promise.resolve(membershipsResponse);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <MembershipCheckoutContext.Provider
      value={{
        joinMembership,
        program,
        setProgram,
        redirectedToConfirmation,
        setRedirectedToConfirmation,
      }}
    >
      {children}
    </MembershipCheckoutContext.Provider>
  );
};
