/**
 * This file contains helper functions to wrap our interactions with localStorage
 */
import Cookies from 'js-cookie';

export const STORE_OBJECT_KEY = 'customStorage';

const getLocalStorageItem = (item: string): void | string => {
  const localStorageItem = localStorage.getItem(item);
  if (!localStorageItem) return;

  return localStorageItem;
};

/**
 * Retrieve the object used to store all non-cookie local data
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getStore = (): Record<string, any> => {
  // there is no localStorage at build time so return empty object instead
  if (typeof localStorage === 'undefined') return {};

  const cookie = Cookies.get(STORE_OBJECT_KEY);

  const store =
    cookie && cookie.length === 0
      ? cookie
      : getLocalStorageItem(STORE_OBJECT_KEY);

  if (!store) localStorage.setItem(STORE_OBJECT_KEY, '{}');

  return store ? JSON.parse(store) : {};
};

/**
 * Add a record to local storage
 */
export const addDataToStore = (data: {
  [prop: string]: boolean | number | string;
}): void => {
  const store = getStore();

  Object.keys(data).forEach((prop: string) => {
    store[prop] = data[prop];
  });

  localStorage.setItem(STORE_OBJECT_KEY, JSON.stringify(store));
};

/**
 * Remove a record from local storage
 */
export const removeDataFromStore = (key: string): void => {
  const store = getStore();

  delete store[key];

  localStorage.setItem(STORE_OBJECT_KEY, JSON.stringify(store));
};
