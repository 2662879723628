import React, { Context, createContext } from 'react';

import useUserContext from './index';
import { UserContextType, UserType } from './types';

export const defaultUser: UserType = {
  activeCredits: 0,
  fetched: false,
  firstName: null,
  lastName: null,
  isMember: false,
  loggedIn: false,
  membershipType: null,
  nextCreditDate: undefined,
  userEmail: null,
  userId: null,
  membershipState: undefined,
};

export const UserContext: Context<UserContextType> =
  createContext<UserContextType>({
    userData: defaultUser,
    /**
     * @deprecated - no longer need to manually retrigger the query
     */
    runEffect: () => {},
  });

export interface UserContextProviderProps {
  value?: UserContextType;
  children?: React.ReactNode;
}
export const UserContextProvider = (props: UserContextProviderProps) => {
  const value = useUserContext();
  return (
    <UserContext.Provider value={{ ...value, ...props.value }}>
      {props.children}
    </UserContext.Provider>
  );
};
