// Custom interceptor to add a fresh JWT to each request
import { QueryClient } from '@tanstack/react-query';
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { getAuthToken } from 'utils/cartHelpers';
import { STORE_BASIC_AUTH_TOKEN } from 'utils/constants/iterable';
import { STORE_ROOT } from 'utils/constants/urls';

// JWT interceptor with Bearer token
export const jwtInterceptor = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  /* this should be set by the /sessions/status.json endpoint in sessionApi.ts on load*/
  if (config.headers.Authorization) return config;
  // TODO: we should be able to remove this after we confirm that the auth token is being set correctly on load in sessionApi.ts
  const authToken = await getAuthToken();
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
    return config;
  }
  return Promise.reject(new Error('No authorization token found'));
};

// JWT interceptor with Basic Auth token
export const basicAuthInterceptor = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
  if (config.headers.Authorization) return config;

  if (STORE_BASIC_AUTH_TOKEN) {
    config.headers.Authorization = `Basic ${STORE_BASIC_AUTH_TOKEN}`;
    return config;
  }
  config.headers.Authorization = '';
  return Promise.reject(new Error('No Basic auth token found'));
};

// Create an axios instance with a base URL and an interceptor
export const createApiInstance = (
  baseURL: string,
  interceptor: (
    config: InternalAxiosRequestConfig,
  ) => Promise<InternalAxiosRequestConfig> | InternalAxiosRequestConfig,
): AxiosInstance => {
  const instance = axios.create({
    baseURL,
  });
  instance.defaults.headers.common['Content-Type'] = 'application/json';
  instance.interceptors.request.use(interceptor, (error) =>
    Promise.reject(error),
  );
  return instance;
};

/**
 * Store API with Axios and Bearer token auth
 */
export const StoreApi = createApiInstance(`${STORE_ROOT}/aapi`, jwtInterceptor);

/**
 * Store API with Axios and Basic token auth
 */
export const BasicStoreApi = createApiInstance(
  `${STORE_ROOT}/aapi`,
  basicAuthInterceptor,
);

/**
 * Create a query client for the `react-query`'s QueryClientProvider
 *
 * See: https://tanstack.com/query/latest/docs/reference/QueryClient
 */
export const queryClient = new QueryClient();
